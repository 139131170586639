import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import '../../Styles/Orders.css';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);

const UpdateItems = () => {
    const [firestoreDetails, setFirestoreDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [editItem, setEditItem] = useState(null);
    const [updatedTitle, setUpdatedTitle] = useState('');
    const [updatedImage, setUpdatedImage] = useState(null);
    const [updatedPrice, setUpdatedPrice] = useState('');
    const [updatedCategory, setUpdatedCategory] = useState('');
    const [updatedShortcut, setUpdatedShortcut] = useState('');
    const [updatedCrustOptions, setUpdatedCrustOptions] = useState([]);

    const [updatedSizes, setUpdatedSizes] = useState({
        small: '',
        medium: '',
        large: '',
        xlarge: ''
    });

    useEffect(() => {
        fetchFirestoreDetails();
    }, []);

    const fetchFirestoreDetails = async () => {
        try {
            const menuItemsSnapshot = await getDocs(collection(firestore, 'MenuItems'));
            const menuItems = menuItemsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), source: 'MenuItems' }));
            
            const dealsSnapshot = await getDocs(collection(firestore, 'Deals'));
            const deals = dealsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), source: 'Deals' }));
            
            const popularSnapshot = await getDocs(collection(firestore, 'PopularItems'));
            const Popular = popularSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data(), source: 'PopularItems' }));
    console.log('popular items' , Popular)
            // Combine all the collections
            const details = [...menuItems, ...deals, ...Popular];
            setFirestoreDetails(details);
            console.log(details);
        } catch (error) {
            console.error('Error fetching Firestore details:', error);
        } finally {
            setLoading(false);
        }
    };
    

    const handleEditClick = (item) => {
        setEditItem(item);
        setUpdatedTitle(item.title);
        setUpdatedImage(null);
        setUpdatedPrice(item.price);
        setUpdatedCategory(item.category);
        setUpdatedShortcut(item.shortcut || '');
    
        if (item.category === 'Pizza') {
            const sizePrices = item.sizes.reduce((acc, size) => {
                acc[size.size.toLowerCase()] = size.price;
                return acc;
            }, {});
            setUpdatedSizes(sizePrices);
    
            // Handle Crust as an object (key-value pairs)
            if (item.Crust) {
                setUpdatedCrustOptions(Object.entries(item.Crust).map(([name, price]) => ({
                    name,
                    price: price.toString(),  // Ensure price is a string for form inputs
                })));
            } else {
                setUpdatedCrustOptions([]);  // Default empty array if no crust options exist
            }
        }
    };
    
   
    
    
    
    
    
    const handleImageUpload = async (file) => {
        const imageRef = ref(storage, `images/${file.name}`);
        try {
            const snapshot = await uploadBytes(imageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            setUpdatedImage(downloadURL); // Store image URL after upload
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    const handleUpdateSubmit = async (e) => {
        e.preventDefault();
    
        const updatedData = {};
    
        if (updatedTitle !== editItem.title) updatedData.title = updatedTitle;
        if (updatedImage && updatedImage !== editItem.image) updatedData.image = updatedImage;
        if (updatedCategory !== editItem.category) updatedData.category = updatedCategory;
        if (updatedPrice !== editItem.price) updatedData.price = parseFloat(updatedPrice);
        if (updatedShortcut !== editItem.Shortcut) updatedData.Shortcut = parseInt(updatedShortcut, 10);
    
        // Update sizes only if changed (for Pizza)
        if (updatedCategory === 'Pizza') {
            const updatedSizesData = {
                small: parseFloat(updatedSizes.small),
                medium: parseFloat(updatedSizes.medium),
                large: parseFloat(updatedSizes.large),
                xlarge: parseFloat(updatedSizes.xlarge)
            };
    
            // Check if any size price is valid, and only add to updatedData if not empty
            const sizesToUpdate = [];
            if (!isNaN(updatedSizesData.small)) sizesToUpdate.push({ size: 'small(7)', price: updatedSizesData.small });
            if (!isNaN(updatedSizesData.medium)) sizesToUpdate.push({ size: 'medium(10)', price: updatedSizesData.medium });
            if (!isNaN(updatedSizesData.large)) sizesToUpdate.push({ size: 'large(13)', price: updatedSizesData.large });
            if (!isNaN(updatedSizesData.xlarge)) sizesToUpdate.push({ size: 'x-large(16)', price: updatedSizesData.xlarge });
    
            if (sizesToUpdate.length > 0) {
                updatedData.sizes = sizesToUpdate;  // Only update if any valid sizes exist
            }
        }
    
        // Handle crust options: Convert the updated array to an object (key-value pairs)
        if (updatedCategory === 'Pizza') {
            const crustObject = updatedCrustOptions.reduce((acc, crust) => {
                if (crust.name && crust.price) {  // Ensure both name and price are valid
                    acc[crust.name] = parseFloat(crust.price);
                }
                return acc;
            }, {});
            if (Object.keys(crustObject).length > 0) {
                updatedData.Crust = crustObject;
            }
        }
    
        // If no fields were changed, alert the user
        if (Object.keys(updatedData).length === 0) {
            alert('No changes were made.');
            return;
        }
    
        try {
            const itemRef = doc(firestore, 'MenuItems', editItem.id);
            await updateDoc(itemRef, updatedData);
            alert('Item updated successfully!');
            setEditItem(null);
            setUpdatedTitle('');
            setUpdatedImage(null);
            setUpdatedPrice('');
            setUpdatedCategory('');
            setUpdatedShortcut('');
            setUpdatedSizes({ small: '', medium: '', large: '', xlarge: '' });
            setUpdatedCrustOptions([]);
            setLoading(true);
            fetchFirestoreDetails();
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };
    
    
    
    
    
    

    const handleRemoveClick = async (itemId, category) => {
        const confirmation = window.confirm("Are you sure you want to remove this item?");
        
        if (!confirmation) {
            return;  // Exit if the user cancels
        }
    
        // Ensure the category and itemId are not undefined or empty
        if (!itemId || !category) {
            alert('Invalid item or category.');
            return;
        }
    
        // Construct references to all relevant collections
        const menuItemRef = doc(firestore, 'MenuItems', itemId);
        const dealsRef = collection(firestore, 'Deals');
        const popularRef = collection(firestore, 'Popular');
    
        try {
            // Check if the item exists in the Deals collection
            const dealsSnapshot = await getDocs(dealsRef);
            const itemInDeals = dealsSnapshot.docs.some(doc => doc.id === itemId);
            
            // Check if the item exists in the Popular collection
            const popularSnapshot = await getDocs(popularRef);
            const itemInPopular = popularSnapshot.docs.some(doc => doc.id === itemId);
    
            // If item exists in either Deals or Popular, do not remove from MenuItems
            if (itemInDeals || itemInPopular) {
                alert("Item cannot be removed because it exists in another collection (Deals or Popular).");
                return;
            }
    
            // If item is not found in Deals or Popular, remove it from MenuItems
            await deleteDoc(menuItemRef);
            alert("Item removed successfully from MenuItems!");
    
            // Reload the data after deletion
            setLoading(true);
            fetchFirestoreDetails();  // Refresh the data in the UI
    
        } catch (error) {
            console.error("Error removing item:", error);
            alert("Failed to remove item. Please try again.");
        }
    };
    
    
    

    return (
        <div className="orders-container">
            <h1 className="orders-title">Update Item</h1>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search items with title or special number (eg: :123)"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            </div>

            {loading ? (
                <p className="loading-text">Loading items...</p>
            ) : (
                <>
                    <ul className="item-list">
                    <ul className="item-list">
    {firestoreDetails.filter(item => item.title.toLowerCase().includes(searchTerm.toLowerCase())).map(item => (
        <li key={item.id} className="item-card">
            <img src={item.image} alt={item.title} className="item-image" />
            <h2 className="item-title">{item.title}</h2>
            <p className="item-price">
                Price: {item.category === 'Pizza' ? 'Varies by size' : `Rs. ${item.price}`}
            </p>
            {/* Display source of the item */}
            <p className="item-source">Source: {item.source}</p>
            
            <button className="add-to-cart-button" onClick={() => handleEditClick(item)}>Update</button>
            <button 
                className="add-to-cart-button"
                style={{borderTop:'1px solid silver'}}
                onClick={() => handleRemoveClick(item.id, item.category)}
            >
                Remove
            </button>
        </li>
    ))}
</ul>

                    </ul>

                    {editItem && (
                        <div className="update-form" >
                            <div className="form-container">
                                <button className="close-btn" onClick={() => setEditItem(null)}>&times;</button>
                                <h2>Edit Item</h2>
                                <form onSubmit={handleUpdateSubmit} style={{height:'50vh',overflowY:'scroll'}}>
                                    <div>
                                        <label>Title:</label>
                                        <input
                                            type="text"
                                            value={updatedTitle}
                                            onChange={(e) => setUpdatedTitle(e.target.value)}
                                        />
                                    </div>
                                    <div>
    <label>Category:</label>
    <input
        type="text"
        value={updatedCategory}
        onChange={(e) => setUpdatedCategory(e.target.value)}
        placeholder="Enter category (e.g., Pizza, Burger)"
    />
</div>

<div>
    <label>Shortcut Key:</label>
    <input
        type="number"
        value={updatedShortcut}
        onChange={(e) => setUpdatedShortcut(e.target.value)}
    />
</div>

                                    <div>
                                        <label>Image:</label>
                                        <input
                                            type="file"
                                            onChange={(e) => handleImageUpload(e.target.files[0])}
                                        />
                                    </div>
                                    {editItem && editItem.category === 'Pizza' ? (
    <div >
        <h3>Pizza Sizes</h3>
        <div>
            <label>Small Price (7 inches):</label>
            <input
                type="number"
                value={updatedSizes.small}
                onChange={(e) => setUpdatedSizes({ ...updatedSizes, small: e.target.value })}
            />
        </div>
        <div>
            <label>Medium Price (10 inches):</label>
            <input
                type="number"
                value={updatedSizes.medium}
                onChange={(e) => setUpdatedSizes({ ...updatedSizes, medium: e.target.value })}
            />
        </div>
        <div>
            <label>Large Price (13 inches):</label>
            <input
                type="number"
                value={updatedSizes.large}
                onChange={(e) => setUpdatedSizes({ ...updatedSizes, large: e.target.value })}
            />
        </div>
        <div>
            <label>X-Large Price (16 inches):</label>
            <input
                type="number"
                value={updatedSizes.xlarge}
                onChange={(e) => setUpdatedSizes({ ...updatedSizes, xlarge: e.target.value })}
            />
        </div>

        <h3>Crust Options</h3>
        {Array.isArray(updatedCrustOptions) && updatedCrustOptions.map((crust, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
                <label>Crust Name:</label>
                <input
                    type="text"
                    value={crust.name}
                    onChange={(e) => {
                        const newCrustOptions = [...updatedCrustOptions];
                        newCrustOptions[index].name = e.target.value;
                        setUpdatedCrustOptions(newCrustOptions);
                    }}
                    placeholder="Crust name"
                />
                <label>Price:</label>
                <input
                    type="number"
                    value={crust.price}
                    onChange={(e) => {
                        const newCrustOptions = [...updatedCrustOptions];
                        newCrustOptions[index].price = e.target.value;
                        setUpdatedCrustOptions(newCrustOptions);
                    }}
                    placeholder="Crust price"
                />
                {updatedCrustOptions.length > 1 && (
                    <button
                        type="button"
                        onClick={() => {
                            const newCrustOptions = updatedCrustOptions.filter((_, i) => i !== index);
                            setUpdatedCrustOptions(newCrustOptions);
                        }}
                    >
                        Remove Crust
                    </button>
                )}
            </div>
        ))}

        <button
            type="button"
            onClick={() => setUpdatedCrustOptions([...updatedCrustOptions, { name: '', price: '' }])}
        >
            Add New Crust Option
        </button>



    </div>
) : (<div>
    <label>Price:</label>
    <input
        type="number"
        value={updatedPrice || ''}
        onChange={(e) => setUpdatedPrice(e.target.value)}
    />
</div>
)}


                                    {/* {editItem.category === 'Pizza' ? (
                                        <>
                                            <div>
                                                <label>Small Price (7 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.small}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, small: e.target.value })}
                                                />
                                            </div>
                                            <div>
                                                <label>Medium Price (10 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.medium}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, medium: e.target.value })}
                                                />
                                            </div>
                                            <div>
                                                <label>Large Price (13 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.large}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, large: e.target.value })}
                                                />
                                            </div>
                                            <div>
                                                <label>X-Large Price (16 inches):</label>
                                                <input
                                                    type="number"
                                                    value={updatedSizes.xlarge}
                                                    onChange={(e) => setUpdatedSizes({ ...updatedSizes, xlarge: e.target.value })}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            <label>Price:</label>
                                            <input
                                                type="number"
                                                value={updatedPrice}
                                                onChange={(e) => setUpdatedPrice(e.target.value)}
                                            />
                                        </div>
                                    )} */}
                                    <button type="submit">Update Item</button>
                                </form>
                                <button className="cancel-btn" onClick={() => setEditItem(null)}>Cancel</button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default UpdateItems;
