import React, { useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, setDoc, doc } from 'firebase/firestore';
// import './AddItems.css'; // Import a CSS file for styling
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
const firebaseConfig = {
    apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
    authDomain: "hungrie-5717f.firebaseapp.com",
    projectId: "hungrie-5717f",
    storageBucket: "hungrie-5717f.appspot.com",
    messagingSenderId: "121136828215",
    appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
    measurementId: ""
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

const AddItems = () => {
    const categories = ['All', 'Pasta', 'Shake', 'Desserts', 'Hot Coffee', 'Cold Drinks', 'Peri Peri', 'Fried', 'Sides', 'Pizza', 'Sandwiches'];

    const defaultSizes = [
        { size: "small(7)", price: 675 },
        { size: "medium(10)", price: 1045 },
        { size: "large(13)", price: 1455 },
        { size: "x-large(16)", price: 1845 },
    ];
   
    // State for menu items
    const [title, setTitle] = useState('');
    const [price, setPrice] = useState('');
    const [image, setImage] = useState('');
    const [category, setCategory] = useState(categories[0]);
    const [isMenu, setIsMenu] = useState(false);
    const [isPopular, setIsPopular] = useState(false);
    const [sizes, setSizes] = useState([]);
    const [promoCategory, setPromoCategory] = useState(categories[0]); // New category state
    const [promoStartDateTime, setPromoStartDateTime] = useState('');
    const [promoEndDateTime, setPromoEndDateTime] = useState('');
    
    // State for deals
    const [dealTitle, setDealTitle] = useState('');
    const [dealPrice, setDealPrice] = useState('');
    const [dealImage, setDealImage] = useState('');
    const [miniData, setMiniData] = useState(['', '', '', '','','','']);
    const [crustOptions, setCrustOptions] = useState([]);

    // State for promo codes
    const [promoCode, setPromoCode] = useState('');
    const [promoPercent, setPromoPercent] = useState('');
    const handleCrustChange = (e, index, field) => {
        const newCrustOptions = [...crustOptions];
        newCrustOptions[index] = { 
            ...newCrustOptions[index], 
            [field]: e.target.value // Dynamically updating the field based on the 'name' or 'price' value
        };
        setCrustOptions(newCrustOptions);
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // If there's an image file selected, upload it
        if (image) {
            // Get a reference to Firebase Storage
            const storage = getStorage();
            const storageRef = ref(storage, 'menu-images/' + image.name); // Use the file name as a reference in storage
    
            try {
                // Upload the file to Firebase Storage
                await uploadBytes(storageRef, image);
    
                // Get the download URL of the uploaded image
                const imageUrl = await getDownloadURL(storageRef);
    
                // Create the new item object with the image URL
                const newItem = {
                    title,
                    price: parseFloat(price),
                    image: imageUrl, // Store the URL of the uploaded image
                    category,
                    isMenu,
                    isPopular,
                    sizes: category === 'Pizza' ? sizes : [],
                    Crust: category === 'Pizza' ? crustOptions.reduce((acc, crust) => {
                        acc[crust.name] = crust.price;
                        return acc;
                    }, {}) : [], // Format the crust options as an object with name as key and price as value
                };
    
                // Now save the data to Firestore
                await setDoc(doc(firestore, 'MenuItems', title), newItem);
                if (isPopular) {
                    await setDoc(doc(firestore, 'PopularItems', title), newItem);
                }
                alert('Item added successfully!');
            } catch (error) {
                console.error('Error uploading image:', error);
                alert('Error uploading image. Please try again.');
            }
        } else {
            alert('Please upload an image before submitting!');
        }
    
        // Reset the form for menu items
        setTitle('');
        setPrice('');
        setImage(null); // Reset the image state
        setCategory(categories[0]);
        setIsMenu(false);
        setIsPopular(false);
        setSizes([]);
        setCrustOptions([]); // Reset crust options after submission
    };
    

    const handleDealSubmit = async (e) => {
        e.preventDefault();
        const newDeal = {
            image: dealImage,
            miniData,
            price: parseFloat(dealPrice),
            title: dealTitle,
        };

        try {
            await setDoc(doc(firestore, 'Deals', dealTitle), newDeal);
            alert('Deal added successfully!');
        } catch (error) {
            console.error('Error adding deal:', error);
            alert('Error adding deal. Please try again.');
        }

        // Reset the form for deals
        setDealTitle('');
        setDealPrice('');
        setDealImage('');
        setMiniData(['', '', '', '']);
    };

    const handlePromoSubmit = async (e) => {
        e.preventDefault();
        const newPromo = {
            code: promoCode,
            percent: parseFloat(promoPercent),
            // category: promoCategory,  // Include category in promo data
            startDateTime: promoStartDateTime,  // Full start datetime
            endDateTime: promoEndDateTime,      // Full end datetime
        };
    
        try {
            await setDoc(doc(firestore, 'Promo', promoCode), newPromo);
            alert('Promo added successfully!');
        } catch (error) {
            console.error('Error adding promo:', error);
            alert('Error adding promo. Please try again.');
        }
    
        // Reset the form for promos
        setPromoCode('');
        setPromoPercent('');
        setPromoCategory(categories[0]);
        setPromoStartDateTime('');
        setPromoEndDateTime('');
    };

    const handleCategoryChange = (e) => {
        const selectedCategory = e.target.value;
        setCategory(selectedCategory);
        if (selectedCategory === 'Pizza') {
            setSizes(defaultSizes.map(size => ({ ...size, price: '' })));
        } else {
            setSizes([]);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
            setImage(file); // Store the file in the state
            // alert('Image selected!');
        }
    };
    
    return (
        <div style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
      
       <div className='for-adding'>
       <div>
       <h2 style={{ color: '#c84347' }}>Add New Item</h2>
            <form onSubmit={handleSubmit} style={formStyle}>
                <div style={{display:'flex',flexDirection:'column'}}>
                    <label>Title:</label>
                    <input 
                        type="text" 
                        value={title} 
                        onChange={(e) => setTitle(e.target.value)} 
                        required 
                        style={inputStyle} 
                    />
                </div>
                <div  style={{display:'flex',flexDirection:'column'}}>
                    <label>Price:</label>
                    <input 
                        type="number" 
                        value={price} 
                        onChange={(e) => setPrice(e.target.value)} 
                        required 
                        style={inputStyle} 
                    />
                </div>
                <div  style={{display:'flex',flexDirection:'column'}}>
                    <label>Image :</label>
                    <input 
                        type="file" 
                      
                        onChange={handleFileChange} 
                        required 
                        style={inputStyle} 
                    />
                </div>
                <div  style={{display:'flex',flexDirection:'column'}}>
                    <label>Category:</label>
                    <select 
                        value={category} 
                        onChange={handleCategoryChange}
                        style={inputStyle}
                    >
                        {categories.map((cat) => (
                            <option key={cat} value={cat}>{cat}</option>
                        ))}
                    </select>
                </div>
                {category === 'Pizza' && (
                    <div  style={{display:'flex',flexDirection:'column'}}>
                        <h3 style={{color:'black'}}>Sizes</h3>
                        {sizes.map((size, index) => (
                            <div key={index} style={{display:'flex',flexDirection:'column'}}>
                                <label>Size: {size.size}</label>
                                <label style={{paddingTop:'10px'}}>Price:</label>
                                <input 
                                    type="number" 
                                    value={size.price} 
                                    onChange={(e) => {
                                        const newSizes = [...sizes];
                                        newSizes[index].price = e.target.value;
                                        setSizes(newSizes);
                                    }} 
                                    required 
                                    style={inputStyle} 
                                />
                            </div>
                        ))}
                    </div>
                )}
                {category === 'Pizza' && (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h3 style={{ color: 'black' }}>Crust Options</h3>
        {crustOptions.map((crust, index) => (
            <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                <label>Crust Name:</label>
                <input
                    type="text"
                    value={crust.name} // Binding value to the correct state property
                    onChange={(e) => handleCrustChange(e, index, 'name')} // Passing 'name' as the field identifier
                    placeholder="Crust name"
                    required
                    style={inputStyle}
                />
                <label>Price:</label>
                <input
                    type="number"
                    value={crust.price} // Binding value to the correct state property
                    onChange={(e) => handleCrustChange(e, index, 'price')} // Passing 'price' as the field identifier
                    placeholder="Crust price"
                    required
                    style={inputStyle}
                />
            </div>
        ))}
        <button
            type="button"
            onClick={() => setCrustOptions([...crustOptions, { name: '', price: '' }])}
            style={buttonStyle}
        >
            Add Crust Option
        </button>
    </div>
)}
                <div  style={{display:'flex',flexDirection:'column'}}>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={isMenu} 
                            onChange={(e) => setIsMenu(e.target.checked)} 
                        />
                        Add to Menu Items
                    </label>
                </div>
                <div  style={{display:'flex',flexDirection:'column',marginTop:'5px',marginBottom:'5px'}}>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={isPopular} 
                            onChange={(e) => setIsPopular(e.target.checked)} 
                        />
                        Add to Popular Items
                    </label>
                </div>
                <button type="submit" style={buttonStyle}>Add Item</button>
            </form>


            <h2 style={{ color: '#c84347' }}>Add New Promo Code</h2>
            <form onSubmit={handlePromoSubmit} style={formStyle}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Promo Code:</label>
            <input
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                required
                style={inputStyle}
            />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Discount Percentage:</label>
            <input
                type="number"
                value={promoPercent}
                onChange={(e) => setPromoPercent(e.target.value)}
                required
                style={inputStyle}
            />
        </div>
        {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Category:</label>
            <select
                value={promoCategory}
                onChange={(e) => setPromoCategory(e.target.value)}
                style={inputStyle}
            >
                {categories.map((cat) => (
                    <option key={cat} value={cat}>{cat}</option>
                ))}
            </select>
        </div> */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Start Date and Time:</label>
            <input
                type="datetime-local"
                value={promoStartDateTime}
                onChange={(e) => setPromoStartDateTime(e.target.value)}
                required
                style={inputStyle}
            />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>End Date and Time:</label>
            <input
                type="datetime-local"
                value={promoEndDateTime}
                onChange={(e) => setPromoEndDateTime(e.target.value)}
                required
                style={inputStyle}
            />
        </div>
        <button type="submit" style={buttonStyle}>Add Promo</button>
    </form>

</div>
<div>
<h2 style={{ color: '#c84347' }}>Add New Deal</h2>
            <form onSubmit={handleDealSubmit} style={formStyle}>
                <div  style={{display:'flex',flexDirection:'column'}}>
                    <label>Deal Title:</label>
                    <input 
                        type="text" 
                        value={dealTitle} 
                        onChange={(e) => setDealTitle(e.target.value)} 
                        required 
                        style={inputStyle} 
                    />
                </div>
                <div  style={{display:'flex',flexDirection:'column'}}>
                    <label>Price:</label>
                    <input 
                        type="number" 
                        value={dealPrice} 
                        onChange={(e) => setDealPrice(e.target.value)} 
                        required 
                        style={inputStyle} 
                    />
                </div>
                <div  style={{display:'flex',flexDirection:'column'}}>
                    <label>Image URL:</label>
                    <input 
                        type="file" 
                       
                        onChange={handleFileChange} 
                        required 
                        style={inputStyle} 
                    />
                </div>
                {miniData.map((data, index) => (
                    <div key={index}  style={{display:'flex',flexDirection:'column'}}>
                        <label>Item {index + 1}:</label>
                        <input 
                            type="text" 
                            value={data} 
                            onChange={(e) => {
                                const newMiniData = [...miniData];
                                newMiniData[index] = e.target.value;
                                setMiniData(newMiniData);
                            }} 
                            required 
                            style={inputStyle} 
                        />
                    </div>
                ))}
                <button type="submit" style={buttonStyle}>Add Deal</button>
            </form>
   </div>
        </div>     

           
         

           
      

              </div>
    );
}

// Styling objects
const formStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    marginBottom: '20px',
    
};

const inputStyle = {
    width: '98%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginTop:'10px',
};

const buttonStyle = {
    backgroundColor: '#c84347',
    color: '#fff',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
};

// CSS can be placed in a separate file (e.g., AddItems.css) for additional styles.

export default AddItems
