// import React, { useEffect, useState } from 'react'; 
// import { initializeApp } from 'firebase/app';

// import '../../Styles/Orders.css'; // Assuming you have a CSS file for styles


// import { getFirestore, collection, getDocs, doc, writeBatch,updateDoc,setDoc,deleteDoc,getDoc } from 'firebase/firestore';
// import axios from 'axios';
// const firebaseConfig = {
//     apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
//     authDomain: "hungrie-5717f.firebaseapp.com",
//     projectId: "hungrie-5717f",
//     storageBucket: "hungrie-5717f.appspot.com",
//     messagingSenderId: "121136828215",
//     appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
//     measurementId: ""
// };

// const app = initializeApp(firebaseConfig);
// const firestore = getFirestore(app);

// const KitchenOrdersList = () => {
//     const [kitchenOrders, setKitchenOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [Button, setButton] = useState(true);
//     const [error] = useState(null);
//     const [firestoreDetails] = useState([]);
//     const [orders] = useState([]);
    
//     // const [orders, setOrders] = useState([]);
//     const [notifications, setNotifications] = useState(() => {
//         const savedNotifications = localStorage.getItem('deliveryNotifications');
//         return savedNotifications ? JSON.parse(savedNotifications) : {};
//     });


//     const fetchDiningOrders = async () => {
//         try {
//             const querySnapshot = await getDocs(collection(firestore, 'Dinning'));
//             const orders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             console.log('Fetched Dining Orders:', orders);
//             return orders;
//         } catch (error) {
//             console.error('Error fetching Dining Orders:', error);
//             return [];
//         }
//     };

//     // Function to fetch Orders from Firestore
//     const fetchOrders = async () => {
//         try {
//             const querySnapshot = await getDocs(collection(firestore, 'ConfirmInOrders'));
//             const response = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             console.log('Fetched Orders from Firestore:', response);
//             return response;
//         } catch (error) {
//             console.error('Error fetching orders:', error);
//             setLoading(false); // Stop loading on error
//             return [];
//         }
//     };

//     // Function to fetch Firestore Details
//     const fetchFirestoreDetails = async () => {
//         try {
//             const querySnapshot = await getDocs(collection(firestore, 'OrderDetails'));
//             const details = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             console.log('Fetched Firestore Details:', details);
//             return details;
//         } catch (error) {
//             console.error('Error fetching Firestore details:', error);
//             return [];
//         }
//     };

// // Function to fetch Kitchen Orders from Firestore
// const fetchKitchenOrders = async () => {
//     try {
//         const querySnapshot = await getDocs(collection(firestore, 'KitchenOrders'));
//         const orders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

//         // Filter orders to only include those with a status of 'confirmed'
//         const confirmedOrders = orders.filter(order => order.status === 'confirmed' || order.status === 'pending');

//         // Deduplicate orders by their unique id
//         const uniqueOrders = confirmedOrders.reduce((acc, order) => {
//             if (!acc.some(existingOrder => existingOrder.id === order.id)) {
//                 acc.push(order);
//             }
//             return acc;
//         }, []);

//         // Sort by sequence number
//         uniqueOrders.sort((a, b) => b.sequenceNumber - a.sequenceNumber);

//         console.log('Fetched Kitchen Orders:', uniqueOrders);
//         return uniqueOrders; // Return only confirmed and unique kitchen orders
//     } catch (error) {
//         console.error('Error fetching Kitchen Orders:', error);
//         return [];
//     }
// };


//     // Combine and save orders (deduplicate combined list)
//     const combineAndSaveOrders = async (kitchenOrders, allOrders) => {
//         if (allOrders.length === 0) {
//             console.warn('No orders to combine. Exiting combineAndSaveOrders.');
//             return;
//         }

//         // Deduplicate the combined orders by `id`
//         const uniqueOrders = allOrders.reduce((acc, order) => {
//             if (!acc.some(existingOrder => existingOrder.id === order.id)) {
//                 acc.push(order);
//             }
//             return acc;
//         }, []);

//         let highestSequenceNumber = kitchenOrders.reduce((max, order) => {
//             return Math.max(max, order.sequenceNumber || 0);
//         }, 0);

//         let currentSequenceNumber = highestSequenceNumber + 1;
//         const kitchenOrdersRef = collection(firestore, 'KitchenOrders');
//         const batch = writeBatch(firestore);

//         uniqueOrders.forEach(order => {
//             const exists = kitchenOrders.some(kOrder => kOrder.id === order.id);

//             if (!exists) {
//                 const orderRef = doc(kitchenOrdersRef);
//                 batch.set(orderRef, {
//                     ...order,
//                     sequenceNumber: currentSequenceNumber, // Assign current sequence number
//                 });
//                 currentSequenceNumber++; // Increment sequence number for next order
//             } else {
//                 console.log(`Order ID ${order.id} already exists. Skipping...`);
//             }
//         });

//         try {
//             await batch.commit();
//             console.log('Combined orders saved successfully.');
//         } catch (error) {
//             console.error('Error saving combined orders:', error);
//         }
//     };

//     // Fetch data on component mount and rerun every 3 seconds
//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 // Fetch orders from all collections
//                 const [ordersResponse, firestoreDetailsResponse, kitchenOrders, diningOrders] = await Promise.all([
//                     fetchOrders(),
//                     fetchFirestoreDetails(),
//                     fetchKitchenOrders(),
//                     fetchDiningOrders(),  // New call for DiningOrders
//                 ]);
    
//                 // Combine all the orders
//                 const allOrders = [
//                     ...ordersResponse, 
//                     ...firestoreDetailsResponse, 
//                     ...diningOrders  // Add DiningOrders to the combined list
//                 ];
    
//                 // Deduplicate combined orders
//                 const uniqueOrders = allOrders.reduce((acc, order) => {
//                     if (!acc.some(existingOrder => existingOrder.id === order.id)) {
//                         acc.push(order);
//                     }
//                     return acc;
//                 }, []);
    
//                 // Optionally, you can merge the Dining Orders with the Kitchen Orders if needed
//                 setKitchenOrders(kitchenOrders);
    
//                 // Save combined orders to Firestore
//                 await combineAndSaveOrders(kitchenOrders, uniqueOrders);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };
//         // Start fetching data when component mounts
//         fetchData();

//         // Set an interval to rerun fetchData every 3 seconds (3000ms)
//         const intervalId = setInterval(fetchData, 3000);

//         // Cleanup the interval when the component unmounts
//         return () => clearInterval(intervalId);

//     }, []);
    

// // Only run once when the component mounts

    
    
    
      

// const deleteOrdersBySequence = async () => {
//     try {
//         const ordersToDelete = kitchenOrders.filter(order => order.sequenceNumber >= 1 && order.sequenceNumber <= 10);
        
//         if (ordersToDelete.length === 0) {
//             console.log("No orders to delete.");
//             return;
//         }

//         const batch = writeBatch(firestore);

//         ordersToDelete.forEach(order => {
//             const orderRef = doc(firestore, 'KitchenOrders', order.id);
//             batch.delete(orderRef);  // Add delete operation to batch
//         });

//         await batch.commit();
//         console.log('Orders with sequence numbers 1-10 have been deleted.');
        
//         // Update local state to reflect the deletion
//         setKitchenOrders(prevOrders => prevOrders.filter(order => order.sequenceNumber < 1 || order.sequenceNumber > 10));
//     } catch (error) {
//         console.error('Error deleting orders:', error);
//     }
// };


//     if (loading) {
//         return <p>Loading kitchen orders...</p>;
//     }

//     if (error) {
//         return <p>Error loading kitchen orders: {error}</p>;
//     }

    


    
//     const handleToDeliver = async (order) => { 
//         const toDeliverRef = collection(firestore, 'ToDeliver');
//         const kitchenOrdersRef = doc(firestore, 'KitchenOrders', order.id); // Reference to the specific order in KitchenOrders collection
//         const confirmInOrdersRef = doc(firestore, 'ConfirmInOrders', order.id); // Reference to the specific order in ConfirmInOrders collection
//         const orderData = { ...order, status: 'to-deliver' };
    
//         try {
//             // Log to confirm we're working with the correct order ID
//             console.log('Processing order with ID:', order.id);
    
//             // Add order to the 'ToDeliver' collection
//             await setDoc(doc(toDeliverRef), orderData);
//             console.log('Order added to To Deliver:', orderData);
    
//             // Remove the order from the 'KitchenOrders' collection
//             await deleteDoc(kitchenOrdersRef);
//             console.log(`Order ${order.id} removed from KitchenOrders.`);
    
//             // Try to remove the order from 'ConfirmInOrders' collection
//             const confirmDoc = await getDoc(confirmInOrdersRef);
//             if (confirmDoc.exists()) {
//                 // If exists, delete it
//                 await deleteDoc(confirmInOrdersRef);
//                 console.log(`Order ${order.id} removed from ConfirmInOrders.`);
//             } else {
//                 // If not found, log the message and update the order status to 'completed'
//                 console.log(`Order ${order.id} not found in ConfirmInOrders, changing status to 'completed'.`);
    
//                 // Only update the status to 'completed' if the document does not exist
//                 const updatedOrderData = { status: 'completed' }; // Only updating the status field
//                 await updateDoc(confirmInOrdersRef, updatedOrderData); // Update the status field in ConfirmInOrders
//             }
    
//             // Update notifications
//             const newNotification = `Order #${order.id} added to delivery.`;
//             setButton(false);
//             setNotifications(prev => {
//                 const updatedNotifications = { ...prev, [order.id]: newNotification };
//                 localStorage.setItem('deliveryNotifications', JSON.stringify(updatedNotifications));
//                 return updatedNotifications;
//             });
    
//             // Update local state to reflect the removal of the order
//             setKitchenOrders(prevOrders => {
//                 // Filter out the order locally after deletion from Firestore
//                 const updatedOrders = prevOrders.filter(o => o.id !== order.id);
//                 return updatedOrders;
//             });
    
//         } catch (error) {
//             console.error('Error processing order for delivery:', error);
//         }
//     };
    
    
    
    
    
    
    

 
    
    
    
//     function Refresh() {
//         window.location.reload();
//     }
    
    
    
//     return (
//         <div className="orders-container">
  
//             <h1 className="title">Orders for Kitchen </h1>
//             <p style={{textAlign:"center"}}>Please check orders in order to refresh the orders in kitchen </p>
//             <button onClick={deleteOrdersBySequence} style={{ margin: '20px' }}>Delete Orders with Sequence 1-10</button>
//             <ul className="orders-list">
//                 {kitchenOrders.map(order => (
//                     <li key={order.id} className="order-item">
//                         <p>
//                             Sequence Number: <span style={{ fontWeight: 'bold' }}>{order.sequenceNumber}</span>
//                         </p>
//                         <p className="order-details">
//                             - Status: <span className="status">{order.status || 'pending'}</span>
//                         </p>
//                         {/* Add more order details as needed */}
//                     </li>
//                 ))}
//             </ul>
//             <ul className="orders-list">
                
//                 {kitchenOrders.map(order => (
//                       <>
                      
//                     <li key={order.id} className="order-item">
//                     {notifications[order.id] && (
//                             <div className="notification" style={{display:'flex',alignItems:'center',gap:'5px'}}>
//                                 <p style={{fontSize:'20px',color:'red'}}>{notifications[order.id]}</p>
//                                 {/* <button onClick={() => clearNotification(order.id)} style={{height:'fit-content',backgroundColor:'#c84347',color:'white'}}>Clear</button> */}
//                             </div>
//                         )}
//                         <p style={{ textDecoration: 'underline' }}>
//                             Sequence Number: <span style={{ fontWeight: 'bold' }}>{order.sequenceNumber}</span>
//                         </p>
//                         <p className="order-details">
//                             {/* Order <span className="order-number">#{order.id}
//                                 </span> 
//                           Order <span className="order-number">#{order.items[0].orderNumber}</span>  */}

//                                 {/* - Total: <span className="total">{order.total || order.totalPrice || order.totalAmount} {order.currency}</span>  */}
                                
//                                 - Status: <span className="status">{order.status || 'pending'}</span>
//                         </p>
//                         <ul>
                        
//                         {(Array.isArray(order.cartItems) && order.cartItems.length > 0) || (Array.isArray(order.items) && order.items.length > 0) ? (
//      (order.cartItems || order.items).map((item) => (
//             <li key={item.id} className="line-item">
//                 {/* <span style={{ fontWeight: 'bold' }}>{item.title}</span> - Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span> - Price: <span className="item-price">{item.price}</span> */}
//                 <p>Title: <span style={{ fontWeight: 'bold' }}>{item.title}</span></p>
//                                         <p>Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span></p>
//                                         <p>Flavor: <span style={{ fontWeight: 'bold' }}>{item.flavor || 'none'}</span></p>
                                       
//                                         {item.title.toLowerCase().includes('pizza' || 'Pizza') && item.crust && (
//           <>
//              {/* <p>Crust: <span style={{ fontWeight: 'bold' }}>{item.crust || item.selectedCrust || 'not avaliable '}</span></p> */}
//           </>
//         )}
//         <p>Crust: <span style={{ fontWeight: 'bold' }}>{item.crust || item.selectedCrust || 'not avaliable '}</span></p>
//                                         {/* <p>Price: <span style={{ fontWeight: 'bold' }}>{item.price}</span></p> */}
//                 {/* Check for toppings and render them if available */}
//                 {Array.isArray(item.toppings) && item.toppings.length > 0 ? (
//                     <div className="item-toppings">
//                         <h4>Toppings:</h4>
//                         <ul>
//                             {item.toppings.map((topping, index) => (
//                                 <li key={index}>{topping}</li>
//                             ))}
//                         </ul>
//                     </div>
//                 ) : (
//                     <p>No toppings available.</p>
//                 )}

//                 {/* Check for options and render them if available */}
//                 {item.options && Object.keys(item.options).length > 0 && (
//                     <div className="item-options">
//                         <h4>Options:</h4>
//                         <ul>
//                             {Object.entries(item.options).map(([option, value]) => (
//                                 <li key={option}>
//                                     <strong>{option}:</strong> {value ? 'Yes' : 'No'}
//                                 </li>
//                             ))}
//                         </ul>
//                     </div>
//                 )}
//             </li>
//         ))
//     ) : (
//         <p>No items in this order.</p>
//     )}




//                             {order.line_items && order.line_items.length > 0 ? (
//                                 order.line_items.map(item => (
//                                     <li key={item.id} className="line-item" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                        
//                                         {/* <img src={item.image.src} alt={item.name} style={{ maxWidth: '100px', marginBottom: '10px' }} /> */}
//                                         <li key={item.id} className="line-item">
//                                         <p>Title: <span style={{ fontWeight: 'bold' }}>{item.name}</span></p>
//                                         <p>Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span></p>
//                                         <p>Price: <span style={{ fontWeight: 'bold' }}>{item.price}</span></p>
// </li>
//                                         {/* Displaying Topping Options, excluding _ywapo_meta_data */}
//                                         {item.meta_data && item.meta_data.length > 0 ? (
//                                             <div className="topping-options">
//                                                 <h4>Topping Options:</h4>
//                                                 <ul>
//                                                     {item.meta_data
//                                                         .filter(meta => meta.key !== '_ywapo_meta_data') // Exclude unwanted key
//                                                         .map((meta, index) => (
//                                                             <li key={index}>
//                                                                 <strong>{meta.display_key}:</strong> {String(meta.display_value)}
//                                                             </li>
//                                                         ))}
//                                                 </ul>
//                                             </div>
//                                         ) : (
//                                             <p>No topping options available.</p>
//                                         )}

//                                         {/* Button to show billing information */}
                                        
//                                     </li>
//                                 ))
//                             ) : (
//                                 <p></p>
//                             )}
//                         </ul>

//                         {
//                             Button? 
//                             <div className="buttons">

//                             <button className="status-button completed" onClick={()=> handleToDeliver(order) }>
//                                 To Deliever </button>
//                                               </div>
//                             :null
//                         }
                       
//                     </li>
                    
                
                  
//                   </>
//                 ))}
//             </ul>


//         </div>
//     );
// };

// export default KitchenOrdersList;








import React, { useEffect, useState } from 'react';
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore, collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import '../../Styles/Orders.css';

// Firebase Configuration
const checkoutFirebaseConfig = {
  apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
  authDomain: "hungrie-5ac9a.firebaseapp.com",
  projectId: "hungrie-5ac9a",
  storageBucket: "hungrie-5ac9a.firebasestorage.app",
  messagingSenderId: "289726512774",
  appId: "1:289726512774:web:0f7757539451129551db07",
  measurementId: "G-S5757JJMHX"
};

// Initialize Firebase app for checkout with a unique name
const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore = getFirestore(checkoutApp);

const Orders = () => {
  const [orders, setOrders] = useState([]); // Store orders
  const [loading, setLoading] = useState(true); // Loading state
  const [changeText, setchangeText] = useState(true); // Loading state
  // Function to fetch orders from Firestore
  const fetchOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'Orders'));
      const ordersData = querySnapshot.docs.map(doc => {
        const order = { id: doc.id, ...doc.data() };
        if (order.createdAt && order.createdAt.seconds !== undefined) {
          return order;
        } else {
          order.createdAt = { seconds: 0 }; // Default value if `createdAt` is not available
          return order;
        }
      });

      // Filter orders where status is 'processing' and sort by SequenceNumber (smallest to biggest)
      const processingOrders = ordersData.filter(order => order.status === 'processing' || order.status === 'started');
      processingOrders.sort((a, b) => a.SequenceNumber - b.SequenceNumber); // Sorting by SequenceNumber
      setOrders(processingOrders);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
    }
  };

  // Fetch orders when the component mounts and set an interval for periodic fetching
  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrders();
    }, 3000); // Refresh orders every 3 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  // Function to update the status of an order to 'out-for-delivery'
  const addItemToConfirmedOrder = async (order) => {
    try {
      // Determine the status based on the order.From value
      let status;
      if (order.From === 'Dinning') {
        status = 'completed-dinning';
      } else {
        status = 'for-delivery';
      }
  
      // Reference to the order document in Firestore
      const orderRef = doc(firestore, 'Orders', order.id);
  
      // Update the status in Firestore
      await updateDoc(orderRef, { status });
  
      // Update the local state with the new status
      const updatedOrders = orders.map(o => 
        o.id === order.id ? { ...o, status } : o
      );
      setOrders(updatedOrders);
  
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };
  const addItemToStartOrder = async (order) => {
    setchangeText(false)
    try {
      // Determine the status based on the order.From value
      let status;
      if (order.From === 'Dinning') {
        status = 'completed-dinning';
      } else {
        status = 'started';
      }
  
      // Reference to the order document in Firestore
      const orderRef = doc(firestore, 'Orders', order.id);
  
      // Update the status in Firestore
      await updateDoc(orderRef, { status });
  
      // Update the local state with the new status
      const updatedOrders = orders.map(o => 
        o.id === order.id ? { ...o, status } : o
      );
      setOrders(updatedOrders);
      setchangeText(true)
    } catch (error) {
      setchangeText(true)

      console.error('Error updating order status:', error);
    }
  };

  return (
    <div className="orders-container">
      <h1 className="title">Kitchen Orders</h1>

      {loading ? (
        <p className="loading-text">Loading orders...</p>
      ) : (
        <ul className="orders-list">
          {orders.length > 0 ? (
            orders.map((order) => (
              <li key={order.id} className="order-item">
                 <strong>Sequence Number: </strong> {order.SequenceNumber} <br />
                <p className="order-details" style={{marginTop:'20px',marginBottom:'20px'}}>
                Order  <span className="order-number">#{order.orderNumber}</span> - 
                {/* {order.items && order.items.length > 0 ? (
                  order.items.map((item, index) => (
                    <>
                                        <span className="order-number">#{item.orderNumber}</span> - 

                    </>
                  ))
                ) : (
                  <p>No order number</p>
                )} */}
               
              </p>
                <p className="order-details">
                  {/* Displaying all data for each order */}
                  {/* <strong>From: </strong> {order.From} <br />
                
                  <strong>Billing Details:</strong> <br />
                  <ul>
                    <li><strong>First Name:</strong> {order.billingDetails?.firstName}</li>
                    <li><strong>Last Name:</strong> {order.billingDetails?.lastName}</li>
                    <li><strong>Email:</strong> {order.billingDetails?.email}</li>
                    <li><strong>Phone:</strong> {order.billingDetails?.phone}</li>
                    <li><strong>Address:</strong> {order.billingDetails?.streetAddress}, {order.billingDetails?.city}, {order.billingDetails?.state} {order.billingDetails?.postcode}</li>
                  </ul> */}

                  <strong>Additional Note: </strong> {order.billingDetails?.additionalNote || 'No notes'} <br />
                  {/* <strong>Status: </strong> {order.status} <br /> */}
                  {/* <strong>Created At: </strong> {new Date(order.createdAt.seconds * 1000).toLocaleString()} <br /> */}
                  {/* <strong>Total Amount: </strong> {order.totalAmount} {order.currency} <br /> */}

                  <ul className="order-items" style={{marginTop:'20px'}}>
                    <strong>Items:</strong>
                    {order.items && order.items.length > 0 ? (
                      order.items.map((item, index) => (
                        <li key={index} className="order-item-detail" >
                          <img src={item.image} alt={item.title} className="item-image" style={{ maxWidth: '100px' }} />
                          <div style={{marginTop:'10px'}}>
                            <span className="item-name" style={{fontWeight:'bold',fontSize:'18px',color:'#c84347'}}>{item.title}</span> - 
                            <span className="item-quantity">Qty: {item.quantity}</span><br />
                            {/* <strong style={{marginTop:'20px',}}>Price:</strong> {item.price} <br /> */}
                            {item.crust && <><strong>Crust:</strong> {item.crust} <br /></>}
                            {item.size && <><strong>Size:</strong> {item.size} <br /></>}
                            {item.toppings?.length > 0 && <><strong>Toppings:</strong> {item.toppings.join(', ')} <br /></>}
                            {item.sauces?.length > 0 && <><strong>Sauces:</strong> {item.sauces.join(', ')} <br /></>}

                          </div>
                        </li>
                      ))
                    ) : (
                      <p>No items</p>
                    )}
                  </ul>

                  {/* Display a button to mark the order as ready */}
                  {order.status === 'started' && (
                    <button
                      onClick={() => addItemToConfirmedOrder(order)}
                      className="status-button completed"
                      style={{ marginTop: '30px',backgroundColor:'#c84347' }}
                    >
                      Order is ready
                    </button>
                  )}
                  {order.status === 'processing' && (
                    <button
                      onClick={() => addItemToStartOrder(order)}
                      className="status-button completed"
                      style={{ marginTop: '30px' }}
                    >
                     
                      {
                        changeText?
                        'Start Making' : 'Starting'
                      }
                    </button>
                  )}
                </p>
              </li>
            ))
          ) : (
            <p>No orders in processing status</p>
          )}
        </ul>
      )}
    </div>
  );
};

export default Orders;
