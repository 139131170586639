// import React, { useEffect, useState } from 'react';
// import { initializeApp } from 'firebase/app';
// import { getFirestore, collection, getDocs } from 'firebase/firestore';
// import '../../Styles/Orders.css'; // Assuming you have a CSS file for styles
// import { doc, updateDoc } from 'firebase/firestore';
// import axios from 'axios';

// const firebaseConfig = {
//     apiKey: "AIzaSyDChQxXuYzS-h8HqOn2n-DNQ_NN6sESnyI",
//     authDomain: "hungrie-5717f.firebaseapp.com",
//     projectId: "hungrie-5717f",
//     storageBucket: "hungrie-5717f.appspot.com",
//     messagingSenderId: "121136828215",
//     appId: "1:121136828215:android:a1c521061dc92fc8a794b2",
//     measurementId: ""
// };

// const app = initializeApp(firebaseConfig);
// const firestore = getFirestore(app);

// const KitchenOrdersList = () => {
//     const [kitchenOrders, setKitchenOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [selectedBilling, setSelectedBilling] = useState(null);
//     const [selectedBilling2, setSelectedBilling2] = useState(null);
//     const [showModal, setShowModal] = useState(false);
//     const [sortOrder, setSortOrder] = useState('recentToOld'); // State for sorting order
//     const [filterStatus, setFilterStatus] = useState('all'); // Filter by status (default is 'all')

//     useEffect(() => {
//         const fetchKitchenOrders = async () => {
//             try {
//                 const querySnapshot = await getDocs(collection(firestore, 'CompletedOrders'));
//                 const orders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

//                 // Sort orders by sequence number (most recent first by default)
//                 orders.sort((a, b) => b.sequenceNumber - a.sequenceNumber);

//                 console.log('Fetched Kitchen Orders:', orders);
//                 setKitchenOrders(orders);
//             } catch (err) {
//                 console.error('Error fetching KitchenOrders:', err);
//                 setError(err.message);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchKitchenOrders();
//     }, []);

//     const handleSortToggle = () => {
//         setSortOrder((prevOrder) => (prevOrder === 'recentToOld' ? 'oldToRecent' : 'recentToOld'));
//     };

//     // Filter the orders based on selected filter status (all, completed, etc.)
//     const filteredOrders = kitchenOrders.filter(order => {
//         if (filterStatus === 'all') return true;
//         return order.status === filterStatus;
//     });

//     // Sort the orders based on the selected sort order, only if `createdAt` is valid
//     const sortedOrders = filteredOrders.sort((a, b) => {
//         if (a.createdAt && a.createdAt.seconds && b.createdAt && b.createdAt.seconds) {
//             if (sortOrder === 'recentToOld') {
//                 return b.createdAt.seconds - a.createdAt.seconds; // Most recent first
//             } else {
//                 return a.createdAt.seconds - b.createdAt.seconds; // Oldest first
//             }
//         }
//         return 0;
//     });

//     const handleShowModal = (billing, order) => {
//         if (billing) {
//             setSelectedBilling(billing);
//         } else {
//             setSelectedBilling2(order);
//         }
//         setShowModal(true);
//     };

//     const handleCloseModal = () => {
//         setShowModal(false);
//         setSelectedBilling(null);
//     };

//     if (loading) {
//         return <p>Loading orders...</p>;
//     }

//     if (error) {
//         return <p>Error loading orders: {error}</p>;
//     }

//     // Handling filter changes (filter by status)
//     const handleFilterChange = (event) => {
//         setFilterStatus(event.target.value);
//     };

//     return (
//         <div className="orders-container">
//             <h1 className="title">Delivered Orders</h1>

//             {/* Sort Button */}
//             <button onClick={handleSortToggle} className="sort-button">
//                 Sort by: {sortOrder === 'recentToOld' ? 'Recent to Old' : 'Old to Recent'}
//             </button>

//             {/* Filter Dropdown */}
//             <select value={filterStatus} onChange={handleFilterChange} className="filter-dropdown">
//                 <option value="all">All Orders</option>
//                 <option value="completed">Completed</option>
//                 <option value="pending">Pending</option>
//                 <option value="cancelled">Cancelled</option>
//             </select>

//             <ul className="orders-list">
//                 {sortedOrders.length > 0 ? (
//                     sortedOrders.map(order => (
//                         <li key={order.id} className="order-item">
//                             <p style={{ textDecoration: 'underline' }}>
//                                 Sequence Number: <span style={{ fontWeight: 'bold' }}>{order.sequenceNumber}</span>
//                             </p>
//                             <p className="order-details">
//                                 Order <span className="order-number">#{order.id}</span> - Total: <span className="total">{order.total || order.totalPrice} {order.currency}</span> - Status: <span className="status">{order.status}</span>
//                             </p>
//                             <p>{order.createdAt && order.createdAt.seconds 
//                                 ? new Date(order.createdAt.seconds * 1000).toLocaleString() 
//                                 : 'Invalid Date'}</p>
//                             <ul>
//                                 {(Array.isArray(order.cartItems) && order.cartItems.length > 0) || (Array.isArray(order.items) && order.items.length > 0) ? (
//                                     (order.cartItems || order.items).map((item) => (
//                                         <li key={item.id} className="line-item">
//                                             <p>Title: <span style={{ fontWeight: 'bold' }}>{item.title}</span></p>
//                                             <p>Quantity: <span style={{ fontWeight: 'bold' }}>{item.quantity}</span></p>
//                                             <p>Price: <span style={{ fontWeight: 'bold' }}>{item.price}</span></p>
//                                         </li>
//                                     ))
//                                 ) : (
//                                     <p>No items in this order.</p>
//                                 )}
//                             </ul>
//                             <div className="buttons">
//                                 <button className="status-button completed" onClick={() => { handleShowModal(order.billing, order); }}>
//                                     Show Billing Info
//                                 </button>
//                             </div>
//                         </li>
//                     ))
//                 ) : (
//                     <p>No delivered orders found.</p>
//                 )}
//             </ul>

//             {showModal && (
//                 <div className="modal-overlay">
//                     <div className="modal">
//                         <h2>Billing Information</h2>
//                         {selectedBilling ? (
//                             <div>
//                                 <p><strong>First Name:</strong> {selectedBilling.first_name}</p>
//                                 <p><strong>Last Name:</strong> {selectedBilling.last_name}</p>
//                                 <p><strong>Address 1:</strong> {selectedBilling.address_1}</p>
//                                 <p><strong>Address 2:</strong> {selectedBilling.address_2}</p>
//                                 <p><strong>City:</strong> {selectedBilling.city}</p>
//                                 <p><strong>State:</strong> {selectedBilling.state}</p>
//                                 <p><strong>Postcode:</strong> {selectedBilling.postcode}</p>
//                                 <p><strong>Country:</strong> {selectedBilling.country}</p>
//                                 <p><strong>Phone:</strong> {selectedBilling.phone}</p>
//                                 <p><strong>Email:</strong> {selectedBilling.email}</p>
//                             </div>
//                         ) : (
//                             <p>No billing information available.</p>
//                         )}
//                         <button onClick={handleCloseModal}>Close</button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default KitchenOrdersList;




import React, { useEffect, useState } from 'react';
import { initializeApp, getApps } from 'firebase/app';
import { getFirestore, collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import '../../Styles/Orders.css';

// Firebase Configuration
const checkoutFirebaseConfig = {
  apiKey: "AIzaSyCJKybLqgZ9Jxv8yU6mZ24dZfDrpgdhHf8",
  authDomain: "hungrie-5ac9a.firebaseapp.com",
  projectId: "hungrie-5ac9a",
  storageBucket: "hungrie-5ac9a.firebasestorage.app",
  messagingSenderId: "289726512774",
  appId: "1:289726512774:web:0f7757539451129551db07",
  measurementId: "G-S5757JJMHX"
};

// Initialize Firebase app for checkout with a unique name
const checkoutApp = getApps().find(app => app.name === 'checkoutApp') || initializeApp(checkoutFirebaseConfig, 'checkoutApp');
const firestore = getFirestore(checkoutApp);

const Orders = () => {
  const [orders, setOrders] = useState([]); // Store orders
  const [loading, setLoading] = useState(true); // Loading state

  // Function to fetch orders from Firestore
  const fetchOrders = async () => {
    try {
      const querySnapshot = await getDocs(collection(firestore, 'Orders'));
      const ordersData = querySnapshot.docs.map(doc => {
        const order = { id: doc.id, ...doc.data() };
        if (order.createdAt && order.createdAt.seconds !== undefined) {
          return order;
        } else {
          order.createdAt = { seconds: 0 }; // Default value if `createdAt` is not available
          return order;
        }
      });

      // Filter orders where status is 'processing' and sort by SequenceNumber (smallest to biggest)
      const processingOrders = ordersData.filter(order => order.status === 'completed');
      processingOrders.sort((a, b) => a.SequenceNumber - b.SequenceNumber); // Sorting by SequenceNumber
      setOrders(processingOrders);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setLoading(false);
    }
  };

  // Fetch orders when the component mounts and set an interval for periodic fetching
  useEffect(() => {
    const interval = setInterval(() => {
      fetchOrders();
    }, 3000); // Refresh orders every 3 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  // Function to update the status of an order to 'out-for-delivery'
  const addItemToConfirmedOrder = async (order) => {
    try {
      const orderRef = doc(firestore, 'Orders', order.id);
      await updateDoc(orderRef, { status: 'out-for-delivery' });
      const updatedOrders = orders.map(o => 
        o.id === order.id ? { ...o, status: 'out-for-delivery' } : o
      );
      setOrders(updatedOrders);
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  return (
    <div className="orders-container">
      <h1 className="title">Delivered Orders</h1>

      {loading ? (
        <p className="loading-text">Loading orders...</p>
      ) : (
        <ul className="orders-list">
          {orders.length > 0 ? (
            orders.map((order) => (
              <li key={order.id} className="order-item">
                <p className="order-details" style={{marginTop:'20px',marginBottom:'20px'}}>
                Order 
                {order.items && order.items.length > 0 ? (
                  order.items.map((item, index) => (
                    <>
                                        <span className="order-number">#{item.orderNumber}</span> - 

                    </>
                  ))
                ) : (
                  <p>No order number</p>
                )}
               
              </p>
              <strong>From: </strong> {order.From} <br />
                 <strong>Sequence Number: </strong> {order.SequenceNumber} <br />
                 <strong>Delivered by : </strong> {order.DeliveryBoy} <br />
                 <strong>Recieved by: </strong> {order.ReciverName} <br />
                 <strong>Payment method by client: </strong> {order.PaymentMethodbyClient} <br />
                
                <p className="order-details">
                  {/* Displaying all data for each order */}
                  {/* <strong>From: </strong> {order.From} <br />
                
                  <strong>Billing Details:</strong> <br />
                  <ul>
                    <li><strong>First Name:</strong> {order.billingDetails?.firstName}</li>
                    <li><strong>Last Name:</strong> {order.billingDetails?.lastName}</li>
                    <li><strong>Email:</strong> {order.billingDetails?.email}</li>
                    <li><strong>Phone:</strong> {order.billingDetails?.phone}</li>
                    <li><strong>Address:</strong> {order.billingDetails?.streetAddress}, {order.billingDetails?.city}, {order.billingDetails?.state} {order.billingDetails?.postcode}</li>
                  </ul> */}

                  <strong>Additional Note: </strong> {order.billingDetails?.additionalNote || 'No notes'} <br />
                  {/* <strong>Status: </strong> {order.status} <br /> */}
                  {/* <strong>Created At: </strong> {new Date(order.createdAt.seconds * 1000).toLocaleString()} <br /> */}
                  {/* <strong>Total Amount: </strong> {order.totalAmount} {order.currency} <br /> */}

                  <ul className="order-items" style={{marginTop:'20px'}}>
                    <strong>Items:</strong>
                    {order.items && order.items.length > 0 ? (
                      order.items.map((item, index) => (
                        <li key={index} className="order-item-detail" >
                          <img src={item.image} alt={item.title} className="item-image" style={{ maxWidth: '100px' }} />
                          <div style={{marginTop:'20px'}}>
                            <span className="item-name" style={{marginTop:'20px'}}>{item.title}</span> - 
                            <span className="item-quantity">Qty: {item.quantity}</span><br />
                            <strong>Price:</strong> {item.price} <br />
                            {item.crust && <><strong>Crust:</strong> {item.crust} <br /></>}
                            {item.size && <><strong>Size:</strong> {item.size} <br /></>}
                            {item.toppings?.length > 0 && <><strong>Toppings:</strong> {item.toppings.join(', ')} <br /></>}
                          </div>
                        </li>
                      ))
                    ) : (
                      <p>No items</p>
                    )}
                  </ul>

                  {/* Display a button to mark the order as ready */}
                  {/* {order.status === 'processing' && (
                    <button
                      onClick={() => addItemToConfirmedOrder(order)}
                      className="status-button completed"
                      style={{ marginTop: '30px' }}
                    >
                      Order is ready
                    </button>
                  )} */}
                </p>
              </li>
            ))
          ) : (
            <p>No orders found</p>
          )}
        </ul>
      )}
    </div>
  );
};

export default Orders;
